const kontakt = {
  title: "Kontakt",
  firstname: "Philipp",
  lastname: "von Mauch",
  address: {
    street: "BräuhausstRaße 8",
    plz: "80331",
    location: "München",
    country: "Deutschland",
  },
  email: "info@brasserie-thi.de",
  phone: "089 / 59 99 88 67",
  mobile: "+49 170 112 1337",
  fax: "xxxx",
  googlemaps: "https://goo.gl/maps/dXsd9wvYsXE597bd9",
};

export default kontakt;
