export default function() {
  const publicPath = process.env.BASE_URL;

  const getPath = function(path) {
    let newP = path.split(".jpg");
    if (newP.length >= 2) {
      return publicPath + path;
    }
    newP = path.split(".jpeg");
    if (newP.length >= 2) {
      return publicPath + path;
    }
    newP = path.split(".png");
    if (newP.length >= 2) {
      return publicPath + path;
    }
    newP = path.split(".svg");
    if (newP.length >= 2) {
      return publicPath + path;
    }
    return publicPath + path + ".jpg";
  };

  const setBackGroundPath = (path) => {
    return "url(" + getPath(path) + ")";
  };

  return { getPath, setBackGroundPath };
}
